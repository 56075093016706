/* eslint-disable react-hooks/exhaustive-deps */

import { adminClient } from "@api/admin/AdminClient";
import { MarketingActionSectionProps } from "@components/admin/marketing/MarketingActionAccordion";
import NewsPreviewComponent from "@components/admin/marketing/sections/news/NewsPreview";
import { queryApi } from "@hooks/api";
import { useAsyncEffect } from "@hooks/miscellaneous";
import { Box, FormControl, FormControlLabel, FormHelperText, Input, InputLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles({
    card: {
        marginBottom: 16
    },
    formControl: {
        marginTop: 8,
        marginBottom: 8,
        width: '100%'
    },
    radioGroup: {
        marginTop: 8
    },
    radioForm: {
        marginLeft: 32,
        marginBottom: 8
    }
});

type InteractionType =
    "do-nothing" |
    "external-url" |
    "item-or-shop-id" |
    "internal-url";

export default (props: MarketingActionSectionProps) => {
    const classes = useStyles();

    const [previewText, setPreviewText] = useState("");
    const [previewHeader, setPreviewHeader] = useState("");
    const [previewHeroImageUrl, setPreviewHeroImageUrl] = useState("");

    const [pushText, setPushText] = useState("");

    const [interactionType, setInteractionType] = useState<InteractionType>("do-nothing");

    const [interactionExternalUrl, setInteractionExternalUrl] = useState("");
    const [interactionItemOrShopId, setInteractionItemOrShopId] = useState("");
    const [interactionInternalUrl, setInteractionInternalUrl] = useState("");

    const [imageDimensions] = queryApi(
        async () => previewHeroImageUrl ? await getImageDimensions(previewHeroImageUrl) : undefined,
        [previewHeroImageUrl]);

    const getActionUrl = async () => {
        switch (interactionType) {
            case "external-url": {
                return interactionExternalUrl;
            }

            case "internal-url": {
                return interactionInternalUrl;
            }

            case "item-or-shop-id": {
                const objectTypeResponse = await adminClient().adminNewsObjectTypeGet(interactionItemOrShopId);
                const objectType: string = objectTypeResponse.result;

                return `reshopper://${objectType[0]}/${interactionItemOrShopId}`;
            }

            default: {
                return "";
            }
        }
    };

    useAsyncEffect(
        async () => {
            props.onActionChanged(null);

            if (!imageDimensions)
                return;

            if (!previewText)
                return;

            if (!previewHeroImageUrl)
                return;

            if (!previewHeader)
                return;

            const actionUrl = await getActionUrl();
            props.onActionChanged({
                type: "sendNotificationCenterNotification",
                data: {
                    message: previewText.trim(),
                    pushMessage: pushText,
                    heroImage: {
                        url: previewHeroImageUrl.trim(),
                        width: imageDimensions.width,
                        height: imageDimensions.height
                    },
                    header: previewHeader.trim(),
                    actionUrl: actionUrl.trim()
                } as any
            });
        },
        [
            imageDimensions,
            previewText,
            previewHeader,
            previewHeroImageUrl,
            pushText,
            interactionType,
            interactionExternalUrl,
            interactionItemOrShopId,
            interactionInternalUrl
        ]);

    return <Box style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    }}>
        <Box style={{
            flexGrow: 1
        }}>
            <Box className={classes.card}>
                <Typography variant="h5">
                    Notification center
                    </Typography>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor="hero-image-url">
                        Hero image URL
                        </InputLabel>
                    <Input
                        value={previewHeroImageUrl}
                        onChange={e => setPreviewHeroImageUrl(e.target.value)}
                        placeholder="https://static.reshopper.com/images/announcements/raket.jpg" />
                    <FormHelperText>
                        The direct URL for the hero image.
                        </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink>
                        Header
                        </InputLabel>
                    <Input
                        value={previewHeader}
                        onChange={e => setPreviewHeader(e.target.value)}
                        placeholder="Prøv Reshopper Plus" />
                    <FormHelperText>
                        The header that appears in the notification center.
                        </FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink>
                        Text
                        </InputLabel>
                    <Input
                        value={previewText}
                        onChange={e => setPreviewText(e.target.value)}
                        multiline
                        placeholder="Vi - Anders, Jonas og Nicolai - glæder os til at præsentere nogle store nyheder i appen meget snart. 1000 tak til jer som støtter vores lille team og arbejde ved at abonnere på Reshopper Plus." />
                    <FormHelperText>
                        The text that appears in the notification center.
                        </FormHelperText>
                </FormControl>
            </Box>
            <Box className={classes.card}>
                <Typography variant="h5">
                    Push notification
                    </Typography>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink>
                        Text
                        </InputLabel>
                    <Input
                        value={pushText}
                        onChange={ev => setPushText(ev.target.value)}
                        multiline
                        placeholder="Der er nye nyheder på vej i appen." />
                    <FormHelperText>
                        Leave empty for no push notification.
                        </FormHelperText>
                </FormControl>
            </Box>
            <Box className={classes.card}>
                <Typography variant="h5">
                    Interaction
                    </Typography>
                <RadioGroup
                    value={interactionType}
                    onChange={e => setInteractionType(e.target.value as InteractionType)}
                >
                    <>
                        <FormControlLabel className={classes.radioGroup} value="do-nothing" control={<Radio />} label="Do nothing" />
                    </>

                    <>
                        <FormControlLabel className={classes.radioGroup} value="external-url" control={<Radio />} label="External URL" />
                        <FormControl className={classes.radioForm} disabled={interactionType !== "external-url"}>
                            <InputLabel shrink>
                                URL
                                </InputLabel>
                            <Input
                                value={interactionExternalUrl}
                                onChange={ev => setInteractionExternalUrl(ev.target.value)}
                                placeholder="https://example.com" />
                            <FormHelperText>
                                Specifies the website URL to navigate to on click.
                                </FormHelperText>
                        </FormControl>
                    </>

                    <>
                        <FormControlLabel className={classes.radioGroup} value="item-or-shop-id" control={<Radio />} label="Item or shop ID" />
                        <FormControl
                            className={classes.radioForm}
                            disabled={interactionType !== "item-or-shop-id"}
                        >
                            <InputLabel shrink>
                                ID
                                </InputLabel>
                            <Input
                                value={interactionItemOrShopId}
                                onChange={ev => setInteractionItemOrShopId(ev.target.value)}
                                placeholder="5c7cf03b34c19c0b808effb0" />
                            <FormHelperText>
                                Specifies the ID of an item or shop to open.
                                </FormHelperText>
                        </FormControl>
                    </>

                    <>
                        <FormControlLabel className={classes.radioGroup} value="internal-url" control={<Radio />} label="Internal URL" />
                        <FormControl
                            className={classes.radioForm}
                            disabled={interactionType !== "internal-url"}
                        >
                            <InputLabel shrink>
                                URL
                                </InputLabel>
                            <Input
                                value={interactionInternalUrl}
                                onChange={ev => setInteractionInternalUrl(ev.target.value)}
                                placeholder="reshopper://" />
                            <FormHelperText>
                                Specifies the internal reshopper:// URL to navigate to on click.
                                </FormHelperText>
                        </FormControl>
                    </>
                </RadioGroup>
            </Box>
        </Box>
        <NewsPreviewComponent
            header={previewHeader || "Prøv Reshopper Plus"}
            heroImageUrl={previewHeroImageUrl || "https://static.reshopper.com/images/announcements/raket.jpg"}
            text={previewText || "Vi - Anders, Jonas og Nicolai - glæder os til at præsentere nogle store nyheder i appen meget snart. 1000 tak til jer som støtter vores lille team og arbejde ved at abonnere på Reshopper Plus."} />
    </Box>
}

async function getImageDimensions(url: string): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight
            });
        };
        img.onerror = reject;
        img.src = url;
    });
}