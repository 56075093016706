import { Box } from "@material-ui/core";
import ActivityCenterTop from "./ActivityCenterTop.png";
import AndroidPhone from "./AndroidPhone.svg";

export default function NewsPreviewComponent(props: {
    heroImageUrl: string,
    header: string,
    text: string
}) {
    const margin = 26;

    const frameWidth = 600;
    const frameHeight = 1150;

    const borderRadius = 10;

    const innerWidth = frameWidth - (margin * 2);
    const innerContentWidth = innerWidth - (12 * 2);

    const headingColor = '#664942';

    return <Box style={{
        backgroundImage: 'url(' + AndroidPhone + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: frameWidth,
        height: frameHeight,
        marginLeft: 32,
        boxSizing: 'border-box'
    }}>
        <Box style={{
            margin: margin + 1,
            width: innerWidth,
            overflowY: 'hidden',
            marginTop: 87,
            marginBottom: 0,
            padding: 0,
            backgroundColor: '#63B0D4',
            paddingBottom: 30,
            height: frameHeight - 177,
            display: 'block'
        }}>
            <Box style={{
                backgroundImage: "url(" + ActivityCenterTop + ")",
                height: 192,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
            }} />
            <Box style={{
                margin: 12,
                padding: 12,
                width: innerContentWidth,
                backgroundColor: 'white',
                boxShadow: '0px 0px 3px 3px rgba(0, 0, 0, 0.05)',
                borderRadius
            }}>
                <img alt="Hero" src={props.heroImageUrl} style={{
                    objectFit: 'contain',
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    width: innerContentWidth,
                    margin: -12,
                    marginBottom: 12
                }} />
                <div style={{
                    color: headingColor,
                    margin: '5px 0',
                    fontWeight: 600,
                    fontSize: 17
                }}>
                    {props.header}
                </div>
                <div style={{
                    color: headingColor,
                    whiteSpace: 'pre-line',
                    fontSize: 17
                }}>
                    {props.text}
                </div>
            </Box>
        </Box>
    </Box>;
}