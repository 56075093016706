import { adminClient } from "@api/admin/AdminClient";
import CodeTextArea from "@components/admin/CodeTextArea";
import { Box, CircularProgress, Dialog, DialogContent, IconButton, LinearProgress, List, ListItem, Paper, Typography } from "@material-ui/core";
import AlarmIcon from '@material-ui/icons/Alarm';
import CodeIcon from "@material-ui/icons/Code";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import RefreshIcon from "@material-ui/icons/Refresh";
import { JobQueueResponse } from "@reshopper/admin-client";
import { formatLargeNumber } from "@utils/formatting";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useEffect, useState } from "react";


export default () => {
    const [jobs, setJobs] = useState<JobQueueResponse[]>([]);
    const [code, setCode] = useState("");

    useEffect(
        () => {
            var callback = async () => {
                setJobs(await adminClient().adminMarketingJobsGet());
                timerId = setTimeout(
                    callback,
                    1000);
            };

            var timerId = setTimeout(
                callback,
                1000);

            return () => clearTimeout(timerId);
        },
        []);

    async function refreshJobsList() {
        setJobs([]);
        setJobs(await adminClient().adminMarketingJobsGet());
    }

    async function onDeleteClicked(jobId: string) {
        await adminClient().adminMarketingDelete(jobId);
        await refreshJobsList();
    }

    async function onRetryClicked(jobId: string) {
        if (!window.confirm("Warning: Retrying a job can (in the worst case) make the latest 1000 users receive your notification again. Do you want to continue?"))
            return;

        await adminClient().adminMarketingRetryPost(jobId);
        await refreshJobsList();

        alert("Retry initiated. The job should now continue.");
    }

    if (jobs.length === 0)
        return <></>;

    return <Box>
        <Dialog open={!!code} onClose={() => setCode("")} fullWidth>
            <DialogContent>
                <CodeTextArea code={code} height={500} />
            </DialogContent>
        </Dialog>
        <Typography variant="h4" style={{
            marginTop: 32
        }}>
            Jobs
        </Typography>
        <List>
            {jobs.map(job => <Paper key={job.id} style={{
                position: 'relative',
                overflow: 'hidden',
                marginTop: 8
            }}>
                <LinearProgress
                    color={job.finishedUtc ? "secondary" : "primary"}
                    variant="determinate"
                    value={100 / job.totalCountApproximation * job.queuedItemCount}
                    style={{
                        height: 3,
                        width: '100%',
                        position: 'absolute',
                        bottom: 0
                    }} />
                <ListItem style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    display: 'flex'
                }}>
                    <Box display="flex">
                        {renderStatusIcon(job)}
                    </Box>
                    <Box display="flex" style={{
                        flexGrow: 1
                    }} />
                    <Box display="flex"
                        style={{
                            marginRight: 32,
                        }}>
                        {job.scheduledForUtc ?
                            <Typography color="secondary">Scheduled for: {toDanishDateFormat(job.scheduledForUtc, "PPPPpppp")} </Typography> :
                            ""}
                    </Box>
                    <Box display="flex">
                        <Typography color="primary">Created: {toDanishDateFormat(job.createdUtc, "PPPPpppp")}</Typography>
                    </Box>
                    <Box display="flex" style={{
                        marginLeft: 32,
                        marginRight: 32
                    }}>
                        <Typography align="right" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {formatLargeNumber(job.queuedItemCount)}
                            <b>/</b>
                            {formatLargeNumber(job.totalCountApproximation)}
                        </Typography>
                    </Box>
                    <Box display="flex">
                        <IconButton onClick={() => setCode(JSON.stringify(job))}>
                            <CodeIcon />
                        </IconButton>
                        <IconButton
                            disabled={!!job.finishedUtc || (job.scheduledForUtc && job.scheduledForUtc > new Date())}
                            onClick={() => onRetryClicked(job.id)}
                        >
                            <RefreshIcon />
                        </IconButton>
                        <IconButton onClick={() => onDeleteClicked(job.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </ListItem>
            </Paper>)}
        </List>
    </Box>
}

const circularProgress = <CircularProgress style={{
    width: 40,
    height: 40
}} />;

function renderStatusIcon(job: JobQueueResponse) {
    const isNowBeforeScheduledUtc = job.scheduledForUtc && job.scheduledForUtc > new Date();
    const isNowAfterScheduledUtc = job.scheduledForUtc && job.scheduledForUtc < new Date();

    if (isNowBeforeScheduledUtc) {
        return <AlarmIcon
            color="secondary"
            style={{
                fontSize: 40
            }} />
    }
    if (!job.finishedUtc) {
        if (isNowAfterScheduledUtc) {
            return circularProgress;
        } else {
            return circularProgress;
        }
    }
    else {
        return <DoneIcon color={job.finishedUtc ? "secondary" : "primary"} style={{
            fontSize: 40
        }} />;
    }
}
