/* eslint-disable react-hooks/exhaustive-deps */

import { MarketingActionSectionProps } from "@components/admin/marketing/MarketingActionAccordion";
import { FormControl, FormHelperText, Input, InputLabel } from "@material-ui/core";
import { useEffect, useState } from "react";

export default (props: MarketingActionSectionProps) => {
    const [dayCount, setDayCount] = useState(0);

    useEffect(
        () => {
            props.onActionChanged(null);

            if(isNaN(dayCount) || dayCount <= 0)
                return;

            props.onActionChanged({
                type: "givePlusMembership",
                data: {
                    days: dayCount
                } as any
            });
        },
        [dayCount]);

    return <FormControl>
        <InputLabel shrink>
            Amount of days
        </InputLabel>
        <Input
            type="number"
            value={dayCount}
            onChange={e => setDayCount(+e.target.value)}
            placeholder="0" />
        <FormHelperText>
            The amount of days to add to the user's Plus membership. If the user is not a Plus user, plus membership is granted.
        </FormHelperText>
    </FormControl>
}