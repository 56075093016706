import { Card, CardContent, FormControl, Switch, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { convertMaterialUiPickersDateToDate } from "@utils/miscellaneous";
import { useState } from "react";

export default (props: {
    selectedDate: Date | null,
    handleDateChange: (date: Date | null) => void
}) => {
    const [isDisabled, setIsEnabled] = useState(true);

    return <>
        <Card style={{
            marginBottom: 16
        }}>
            <Typography variant="h5" style={{
                marginTop: 16,
                marginLeft: 16
            }}>
                Schedule job for later
                <Switch
                    onClick={() => {
                        if (isDisabled) {
                            props.handleDateChange(new Date())
                        } else {
                            props.handleDateChange(null)
                        }
                        setIsEnabled(!isDisabled)
                    }
                    }
                    checked={!isDisabled} />
            </Typography>
            <CardContent>
                <FormControl>
                    <DateTimePicker
                        disabled={isDisabled}
                        autoOk
                        variant="inline"
                        ampm={false}
                        disablePast
                        value={props.selectedDate}
                        onChange={(e) => props.handleDateChange(convertMaterialUiPickersDateToDate(e))}
                        label="Date and Time"
                    />
                </FormControl>
            </CardContent>
        </Card>
    </>
}