import { withAdminMenuLayout } from "@components/admin/Decorators";
import MarketingJobCreation from "@components/admin/marketing/MarketingJobCreation";
import MarketingJobList from "@components/admin/marketing/MarketingJobList";
import { useQueryParameters } from "@hooks/navigation";
import { navigateTo404 } from "@utils/navigation";

interface Props {
    id: string;
}

export default withAdminMenuLayout(function MarketingPageComponent() {
    const props = useQueryParameters<Props>();
    if (!props.id) {
        return navigateTo404();
    }

    return <MarketingPage id={props.id} />
});

function MarketingPage(props: { id: string }): JSX.Element {

    return <>
        <MarketingJobCreation id={props.id} />
        <MarketingJobList />
    </>
}
