import { adminClient } from "@api/admin/AdminClient";
import MarketingActionAccordion from "@components/admin/marketing/MarketingActionAccordion";
import GivePlusMembershipSection from "@components/admin/marketing/sections/GivePlusMembershipSection";
import NewsSection from "@components/admin/marketing/sections/news/NewsSection";
import ScheduleMarketingJobForLaterComponent from "@components/admin/marketing/sections/ScheduleMarketingJobForLaterComponent";
import { queryApi } from "@hooks/api";
import { Button, Card, CardContent, FormControl, FormHelperText, Input, InputLabel, Typography } from "@material-ui/core";
import { CreateActionRequest } from "@reshopper/admin-client";
import { startOfMinute } from "date-fns";
import isBefore from "date-fns/isBefore";
import { useMemo, useState } from "react";

export default (props: { id: string }) => {
    const [kibanaUrl, setKibanaUrl] = useState("");
    const [userIdsString, setUserIdsString] = useState(props.id);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const marketingSections = [
        {
            title: "Announcement",
            component: NewsSection
        },
        {
            title: "Add plus days",
            component: GivePlusMembershipSection
        }
    ];
    const [marketingActions, setMarketingActions] = useState<Array<CreateActionRequest | null | undefined>>(
        new Array<CreateActionRequest>(marketingSections.length).map(() => undefined));

    const [userCountResponse] = queryApi(
        async (options) => {
            setIsLoading(true);
            try {
                return await adminClient().adminMarketingPeekPost({
                    ...options,
                    body: {
                        kibanaUrl: kibanaUrl ? kibanaUrl.trim() : undefined,
                        userIds: getUserIdsFromUserIdsString(userIdsString)
                    }
                });
            } finally {
                setIsLoading(false);
            }
        },
        [kibanaUrl, userIdsString]);

    const userCount = useMemo(
        () => userCountResponse?.result,
        [userCountResponse]);

    const areRequiredValuesMissing = useMemo(
        () => marketingActions.filter(action => action === null).length > 0,
        [marketingActions]);

    function getUserIdsFromUserIdsString(userIdsString: string): string[] | undefined {
        if (!userIdsString?.trim()) {
            return undefined;
        }
        return userIdsString.split(',').map(x => x.trim());
    }

    function handleDateChange(date: Date | null) {
        if (!date) {
            setSelectedDate(null);
            return;
        }
        let now = new Date();
        if (isBefore(date, now)) {
            date = now;
        }
        setSelectedDate(startOfMinute(date));
    };

    function setActionForMarketingSection(
        index: number,
        action: CreateActionRequest | null | undefined) {
        if (JSON.stringify(marketingActions[index]) === JSON.stringify(action))
            return;

        const copyOfActions = [...marketingActions];
        copyOfActions[index] = action;

        setMarketingActions(copyOfActions);
    }

    async function onSendClicked() {
        let confirmText = "Are you sure you want to " + selectedDate ? "schedule" : "start" + " this job, affecting " + userCount + " users?";
        if (kibanaUrl) {
            confirmText += "\nConfirm kibana url is correct."
        }
        if (userIdsString) {
            confirmText += "\nConfirm user ID's is correct."
        }
        if (selectedDate) {
            confirmText += "\nConfirm schedule for later date is correct."
        }
        if (!window.confirm(confirmText))
            return;

        setIsLoading(true);
        try {
            await adminClient().adminMarketingCreatePost({
                body: {
                    kibanaUrl: kibanaUrl ? kibanaUrl.trim() : undefined,
                    userIds: getUserIdsFromUserIdsString(userIdsString),
                    scheduledForUtc: selectedDate ?? undefined,
                    actions: marketingActions
                        .filter(x => !!x)
                        .map(x => x!)
                }
            });
        } finally {
            setIsLoading(false);
        }
    };

    return <>
        <Typography variant="h4" style={{
            marginBottom: 16
        }}>
            Schedule job
        </Typography>
        <Card style={{
            marginBottom: 16
        }}>
            <CardContent>
                <Typography variant="h5">
                    Target audience
                </Typography>
                <FormControl fullWidth>
                    <InputLabel
                        shrink
                        htmlFor="target-audience"
                    >
                        Kibana URL
                    </InputLabel>
                    <Input
                        disabled={!!userIdsString?.trim()}
                        value={kibanaUrl}
                        onChange={ev => setKibanaUrl(ev.target.value)}
                        placeholder="https://example.com" />
                    <FormHelperText>
                        The URL for the Kibana dashboard or filter to use as target audience.
                    </FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel
                        shrink
                        htmlFor="target-audience"
                    >
                        User IDs
                    </InputLabel>
                    <Input
                        disabled={!!kibanaUrl?.trim()}
                        value={userIdsString}
                        onChange={ev => setUserIdsString(ev.target.value)}
                        placeholder="userId,userId,userId" />
                    <FormHelperText>
                        If no Kibana url is provided, the announcement is sent to provided user IDs.
                    </FormHelperText>
                </FormControl>
            </CardContent>
        </Card>
        <ScheduleMarketingJobForLaterComponent
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
        />
        {marketingSections.map((marketingSection, marketingSectionIndex) => <>
            <MarketingActionAccordion
                key={marketingSection.title}
                title={marketingSection.title}
                onActionChanged={action => setActionForMarketingSection(
                    marketingSectionIndex,
                    action)}
            >
                {marketingSection.component}
            </MarketingActionAccordion>
        </>)}
        <Button
            disabled={
                isLoading ||
                areRequiredValuesMissing}
            onClick={() => onSendClicked()}
            variant="contained"
            color="primary"
            style={{
                marginTop: 16,
                alignSelf: 'flex-start',
                display: 'flex'
            }}
        >
            Execute on {userCount ?? 0} users
        </Button>
    </>;
}