/* eslint-disable react-hooks/exhaustive-deps */

import { Accordion, AccordionDetails, AccordionSummary, Switch, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CreateActionRequest } from "@reshopper/admin-client";
import { useEffect, useState } from "react";

export type MarketingActionSection = (props: MarketingActionSectionProps) => JSX.Element;

export type MarketingActionSectionProps = {
    onActionChanged: (action: CreateActionRequest|null) => void;
};

export default (props: {
    title: string,
    onActionChanged: (actions: CreateActionRequest|null|undefined) => void,
    children: MarketingActionSection
}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [action, setAction] = useState<CreateActionRequest|null>(null);

    useEffect(
        () => {
            props.onActionChanged(isEnabled ?
                action :
                undefined);
        },
        [action, isEnabled]);

    return <Accordion 
        expanded={isEnabled}
    >
        <AccordionSummary
            onClick={() => setIsEnabled(!isEnabled)} 
            expandIcon={<ExpandMoreIcon />}
        >
            <Switch checked={isEnabled} />
            <Typography style={{
                display: 'flex',
                alignSelf: 'center'
            }}>
                {props.title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 0
        }}>
            <props.children 
                onActionChanged={action => setAction(action)}
            />
        </AccordionDetails>
    </Accordion>;
}